import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const Title = ({ className, children }: Props) => {
  return (
    <p
      className={`text-3xl font-bold text-gray-800 text-center m-2 ${className}`}
    >
      {children}
    </p>
  );
};
