export type CustomProcessEnv = {
  REACT_APP_CONNECTOR_BASE_URL: string;
  REACT_APP_PARTICIPANT_NAME: string;
  REACT_APP_COUNTER_PARTY_ADDRESSES: string;
  REACT_APP_PARTICIPANT_DID: string;
  REACT_APP_ISSUER_DID: string;
  REACT_APP_ISSUER_PK: string;
  REACT_APP_WALT_ID_URL: string;
  REACT_APP_WALT_ID_CLIENT_ID: string;
};

export type ENVIRONMENT_VARIABLE = keyof CustomProcessEnv;

export function getStringVar(name: ENVIRONMENT_VARIABLE) {
  return ((process.env as CustomProcessEnv)[name] || '').trim();
}

export function getJSONVar(name: ENVIRONMENT_VARIABLE) {
  let json = getStringVar(name);
  if (!json) {
    return {};
  }
  // Remove quotes if present (e.g. in Docker or k8s)
  if (json.startsWith("'") && json.endsWith("'")) {
    json = json.slice(1, -1);
  }
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error(`Error parsing ${name} as JSON: ${e}`);
  }
}

export function getURLVar(name: ENVIRONMENT_VARIABLE): string {
  return getStringVar(name).replace(/\/+$/g, '');
}

export function getURLVars(name: ENVIRONMENT_VARIABLE): string[] {
  return getStringVar(name)
    .split(',')
    .map((s) => s.trim().replace(/\/+$/g, ''));
}
