import { getStringVar, getURLVar, getURLVars } from '@/constants/env';

export const MANAGEMENT_URL: string = (() => {
  return `${getURLVar('REACT_APP_CONNECTOR_BASE_URL')}/management`;
})();

export const IDENTITY_HUB_URL: string = (() => {
  return `${getURLVar('REACT_APP_CONNECTOR_BASE_URL')}/api/identity-hub`;
})();

export const PARTICIPANT_NAME: string = (() => {
  return getStringVar('REACT_APP_PARTICIPANT_NAME');
})();

export const PARTICIPANT_DID: string = (() => {
  return getStringVar('REACT_APP_PARTICIPANT_DID');
})();

export const ISSUER_DID: string = (() => {
  return getStringVar('REACT_APP_ISSUER_DID');
})();

export const COUNTER_PARTY_ADDRESSES: string[] = (() => {
  return getURLVars('REACT_APP_COUNTER_PARTY_ADDRESSES');
})();
