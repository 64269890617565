import { configureStore } from '@reduxjs/toolkit';

import assetsReducer from './assets';
import catalogReducer from './catalog';
import contractsReducer from './contracts';
import credentialsReducer from './credentials';
import policiesReducer from './policies';

export const store = configureStore({
  reducer: {
    assets: assetsReducer,
    policies: policiesReducer,
    contracts: contractsReducer,
    catalog: catalogReducer,
    credentials: credentialsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
