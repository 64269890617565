import React, { useEffect, useState } from 'react';

import { CardView, Title } from '@/components';
import { PARTICIPANT_NAME } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { fetchAssets, selectAssetsState } from '@/store/assets';
import {
  addContract,
  deleteContract,
  fetchContracts,
  selectContractsState,
} from '@/store/contracts';
import { fetchPolicies, Policy, selectPoliciesState } from '@/store/policies';

export function ContractsPage() {
  const [refresh, setRefresh] = useState(false);
  const dispatch = useAppDispatch();
  const contracts = useAppSelector(selectContractsState);
  const assets = useAppSelector(selectAssetsState);
  const policies = useAppSelector(selectPoliciesState);

  useEffect(() => {
    dispatch(fetchContracts());
    dispatch(fetchAssets());
    dispatch(fetchPolicies());
  }, [refresh]);

  return (
    <div>
      <Title className={'text-center'}>Contracts</Title>

      <CardView
        dataCollection={contracts.contractCollection}
        onAction={{
          text: 'Delete',
          fn: async (id) => {
            id && (await deleteContract(id));
            setRefresh(!refresh);
          },
        }}
        properties={{
          Id: ['@id'],
          'Access Policy': ['edc:accessPolicyId'],
          'Contract Policy': ['edc:contractPolicyId'],
          'Asset Selector': ['edc:assetsSelector', 'edc:operandRight'],
        }}
      />
      {PARTICIPANT_NAME === 'imec' && (
        <div className={'flex justify-center'}>
          <form
            className={'flex flex-col max-w-lg m-10'}
            onSubmit={async (form) => {
              form.preventDefault();
              const formData = new FormData(form.currentTarget);
              const data = Object.fromEntries(formData.entries());
              await addContract(
                data['policyId'] as string,
                data['assetId'] as string,
              );
              setRefresh(!refresh);
            }}
          >
            <label className={'text-center'} htmlFor={'policyId'}>
              Policy
            </label>
            <select
              className={'border-2 border-gray-300 rounded-md'}
              defaultValue={''}
              id={'policyId'}
              name={'policyId'}
              required
            >
              {policies.policyCollection.map((policy: Policy) => (
                <option key={policy['@id']} value={policy['@id']}>
                  {policy['edc:policy']['odrl:permission']?.[
                    'odrl:constraint'
                  ]?.['odrl:rightOperand'] || 'Allow All'}
                </option>
              ))}
            </select>
            <label className={'text-center'} htmlFor={'assetId'}>
              Asset Selector
            </label>
            <select
              className={'border-2 border-gray-300 rounded-md'}
              defaultValue={''}
              id={'assetId'}
              name={'assetId'}
            >
              <option value={''} />
              {assets.assetCollection.map((asset) => (
                <option key={asset['@id']} value={asset['@id']}>
                  {asset['edc:properties']['edc:name']}
                </option>
              ))}
            </select>
            <input
              className={
                'shadow bg-gray-600 hover:bg-gray-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 mt-2 rounded'
              }
              type={'submit'}
              value={'Add Contract'}
            />
          </form>
        </div>
      )}
    </div>
  );
}
