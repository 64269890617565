import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Contract } from '@/store/contracts/types';

export type ContractsState = {
  contractCollection: Contract[];
};

const initialState: ContractsState = {
  contractCollection: [],
};
export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setContracts: (state, action: PayloadAction<Contract[]>) => {
      state.contractCollection = action.payload;
    },
  },
});

export default contractsSlice.reducer;
export const { setContracts } = contractsSlice.actions;
