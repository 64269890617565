import React from 'react';

export const NavBarButton = ({ text }: { text: string }) => {
  return (
    <button
      className="ml-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-gray-900 bg-gray-100 hover:bg-gray-200"
      type="button"
    >
      {text}
    </button>
  );
};

export const ActionButton = ({
  text,
  onclick,
  children,
}: {
  text: string;
  onclick: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <button
      className="group ml-4 my-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-gray-900 bg-gray-100 hover:bg-gray-200"
      onClick={onclick}
      type="button"
    >
      {text}
      {children}
    </button>
  );
};
