import { Link, Outlet } from 'react-router-dom';

import { NavBarButton } from '@/components';
import { PARTICIPANT_NAME } from '@/constants';

export function NavBar() {
  return (
    <div>
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <svg
                height="50"
                viewBox="0 0 48 48"
                width="50"
                x="0px"
                xmlns="http://www.w3.org/2000/svg"
                y="0px"
              >
                <path
                  d="M33.044,13.895c-0.24,0.227-0.467,0.455-0.695,0.707c-1.213,1.339-2.185,2.792-2.905,4.345H8.842 l6.316-5.053H33.044z"
                  fill="#00838f"
                ></path>
                <path
                  d="M28.509,21.474c-0.442,1.617-0.657,3.297-0.606,5.053H5.053l6.316-5.053H28.509z"
                  fill="#00838f"
                ></path>
                <path
                  d="M30.278,34.105H0l6.316-5.053h21.815v0.038C28.434,31.049,29.166,32.741,30.278,34.105z"
                  fill="#00838f"
                ></path>
                <path
                  d="M45.084,28.004c0.232,0.747,0.45,1.452,0.669,2.157c0.147,0.472,0.28,0.951,0.45,1.414 c0.096,0.264,0.032,0.397-0.188,0.535c-1.143,0.716-2.369,1.217-3.658,1.538c-1.883,0.47-3.783,0.637-5.694,0.21 c-2.77-0.618-4.455-2.446-4.895-5.346c-0.652-4.295,0.378-8.153,3.226-11.363c1.744-1.965,3.949-2.992,6.503-3.211 c1.257-0.108,2.488-0.04,3.68,0.41c1.802,0.68,2.776,2.178,2.822,4.194c0.064,2.825-1.186,4.797-3.501,6.088 c-1.491,0.832-3.107,1.243-4.765,1.491c-0.664,0.099-1.333,0.169-2.001,0.234c-0.215,0.021-0.282,0.12-0.268,0.326 c0.098,1.466,0.816,2.576,2.386,2.798c1.437,0.204,2.756-0.235,4.038-0.853C44.278,28.439,44.659,28.226,45.084,28.004z M37.815,22.678c1.63-0.163,3.115-0.503,4.334-1.633c0.375-0.348,0.602-0.794,0.692-1.317c0.136-0.789-0.356-1.437-1.114-1.483 c-0.864-0.052-1.586,0.274-2.193,0.897C38.612,20.09,38.149,21.291,37.815,22.678z"
                  fill="#00838f"
                ></path>
              </svg>
              <h1 className="text-lg font-bold text-gray-900 ml-2">
                {PARTICIPANT_NAME.toUpperCase()} Connector Management
              </h1>
            </div>
            <div className="flex items-center">
              <Link to="/">
                <NavBarButton text={'Catalog'} />
              </Link>
              <Link to="/assets">
                <NavBarButton text={'Assets'} />
              </Link>
              <Link to="/policies">
                <NavBarButton text={'Policies'} />
              </Link>
              <Link to="/contracts">
                <NavBarButton text={'Contracts'} />
              </Link>
              <Link to="/credentials">
                <NavBarButton text={'Credentials'} />
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
    </div>
  );
}
