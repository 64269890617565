import { v4 as uuidv4 } from 'uuid';

import { MANAGEMENT_URL } from '@/constants';
import { AppDispatch } from '@/store';
import { setPolicies } from '@/store/policies/slice';

export function fetchPolicies() {
  return async (dispatch: AppDispatch) => {
    const response = await fetch(
      MANAGEMENT_URL + `/v2/policydefinitions/request`,
      {
        method: 'POST',
      },
    );
    const data = await response.json();
    dispatch(setPolicies(data));
  };
}

export async function addPolicy(countryCode?: string) {
  const permission = countryCode
    ? [
        {
          'odrl:action': {
            'odrl:type': 'USE',
          },
          'odrl:constraint': [
            {
              'odrl:leftOperand': 'gx:legalAddress.gx:countrySubdivisionCode',
              'odrl:operator': {
                '@id': 'odrl:eq',
              },
              'odrl:rightOperand': countryCode,
            },
          ],
        },
      ]
    : [];

  await fetch(MANAGEMENT_URL + `/v2/policydefinitions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      '@context': {
        edc: 'https://w3id.org/edc/v0.0.1/ns/',
        odrl: 'http://www.w3.org/ns/odrl/2/',
      },
      '@type': 'PolicyDefinition',
      '@id': uuidv4(),
      'edc:policy': {
        'odrl:permission': permission,
      },
    }),
  });
}

export async function deletePolicy(policyId: string) {
  await fetch(MANAGEMENT_URL + `/v2/policydefinitions/${policyId}`, {
    method: 'DELETE',
  });
}
