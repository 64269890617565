import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Catalog, CatalogError } from '@/store/catalog/types';

export type CatalogState = {
  catalogCollection: Catalog[];
  errors: CatalogError[];
};

const initialState: CatalogState = {
  catalogCollection: [],
  errors: [],
};
export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCatalogs: (state, action: PayloadAction<Catalog[]>) => {
      state.catalogCollection = action.payload;
    },
    setErrors: (state, action: PayloadAction<CatalogError[]>) => {
      state.errors = action.payload;
    },
  },
});

export default catalogSlice.reducer;
export const { setCatalogs, setErrors } = catalogSlice.actions;
