import React, { useEffect, useState } from 'react';

import { ActionButton, CardView, Title, Tooltip } from '@/components';
import { PARTICIPANT_NAME } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  addPolicy,
  deletePolicy,
  fetchPolicies,
  selectPoliciesState,
} from '@/store/policies';

export function PoliciesPage() {
  const [refresh, setRefresh] = useState(false);
  const dispatch = useAppDispatch();
  const policies = useAppSelector(selectPoliciesState);

  useEffect(() => {
    dispatch(fetchPolicies());
  }, [refresh]);

  return (
    <div>
      <Title className={'text-center'}>Policies</Title>

      <CardView
        dataCollection={policies.policyCollection}
        onAction={{
          text: 'Delete',
          fn: async (id) => {
            id && (await deletePolicy(id));
            setRefresh(!refresh);
          },
        }}
        properties={{
          ID: ['@id'],
          'Permission Type': [
            'edc:policy',
            'odrl:permission',
            'odrl:action',
            'odrl:type',
          ],
          Constraint: [
            'edc:policy',
            'odrl:permission',
            'odrl:constraint',
            'odrl:leftOperand',
          ],
          Equals: [
            'edc:policy',
            'odrl:permission',
            'odrl:constraint',
            'odrl:rightOperand',
          ],
        }}
      />
      <div className={'flex justify-center'}>
        <ActionButton
          children={<Tooltip text={'No access policy check enforced'} />}
          onclick={async () => {
            await addPolicy();
            setRefresh(!refresh);
          }}
          text={'Add Allow All Policy'}
        />
        {PARTICIPANT_NAME === 'imec' && (
          <ActionButton
            children={
              <Tooltip text={'Allow only verified BE legal address users'} />
            }
            onclick={async () => {
              await addPolicy('BE-BRU');
              setRefresh(!refresh);
            }}
            text={'Add BE-BRU Policy'}
          />
        )}
      </div>
    </div>
  );
}
