import { getStringVar, getURLVar } from '@/constants/env';

export const WALT_ID_URL: string = (() => {
  return getURLVar('REACT_APP_WALT_ID_URL');
})();

export const WALT_ID_CLIENT_ID: string = (() => {
  return getStringVar('REACT_APP_WALT_ID_CLIENT_ID');
})();

// This is being used to sign the JWTs that are sent to the Identity Hub with the issuer's private key.
// This is not secure, but it is just for demo purposes. Should match with the public key of issuer (did:web:issuer)
// for EDC verification.
// In a more secure scenario, the issuer backend should sign and push the credentials to the Identity Hub.
// Other option is to fetch the private key of issuer, sign the JWT and push it to the Identity Hub (also not secure).
//  1- export the private key from the issuer did:web:issuer#keyId as the
//  https://wallet.waltid.dssrd.imec-apt.be/api/swagger#/Key%20Management/exportKey does not need authentication
//  2- sign the jwt with the private key and send it to the Identity Hub
export const ISSUER_PK: string = (() => {
  return getStringVar('REACT_APP_ISSUER_PK');
})();
