import React from 'react';

type Props = {
  className?: string;
  children?: React.ReactNode;
  text: string;
};

export const Tooltip = ({ className, children, text }: Props) => {
  return (
    <span
      className={`group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute translate-y-full opacity-0 m-4 mx-auto ${className}`}
    >
      {text}
      {children}
    </span>
  );
};
