import { v4 as uuidv4 } from 'uuid';

import { MANAGEMENT_URL, PARTICIPANT_NAME } from '@/constants';
import { AppDispatch } from '@/store';

import { setAssets } from './slice';

export function fetchAssets() {
  return async (dispatch: AppDispatch) => {
    const response = await fetch(MANAGEMENT_URL + `/v3/assets/request`, {
      method: 'POST',
    });
    const data = await response.json();
    dispatch(setAssets(data));
  };
}

export async function addAsset(tenant: string) {
  const id = uuidv4();
  await fetch(MANAGEMENT_URL + `/v3/assets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      '@context': {
        edc: 'https://w3id.org/edc/v0.0.1/ns/',
      },
      '@id': id,
      properties: {
        name: tenant,
        id: id,
        contenttype: 'application/json',
      },
      dataAddress: {
        name: tenant,
        type: 'Ngsild',
        'ngsild.baseUrl': `http://orion.${PARTICIPANT_NAME}.dssrd.imec-apt.be`,
        'ngsild.tenant': tenant,
        'ngsild.entityType':
          'https://smartdatamodels.org/dataModel.MarineTransport/Vessel',
        'ngsild.context':
          'https://raw.githubusercontent.com/smart-data-models/dataModel.MarineTransport/master/context.jsonld',
      },
    }),
  });
}

export async function deleteAsset(assetId: string) {
  await fetch(MANAGEMENT_URL + `/v3/assets/${assetId}`, {
    method: 'DELETE',
  });
}
