import { v4 as uuidv4 } from 'uuid';

import { MANAGEMENT_URL } from '@/constants';
import { AppDispatch } from '@/store';
import { setContracts } from '@/store/contracts/slice';

export function fetchContracts() {
  return async (dispatch: AppDispatch) => {
    const response = await fetch(
      MANAGEMENT_URL + `/v2/contractdefinitions/request`,
      {
        method: 'POST',
      },
    );
    const data = await response.json();
    dispatch(setContracts(data));
  };
}

export async function addContract(policyId: string, assetId?: string) {
  const assetSelector = assetId
    ? [
        {
          '@type': 'CriterionDto',
          'edc:operandLeft': 'https://w3id.org/edc/v0.0.1/ns/id',
          'edc:operator': '=',
          'edc:operandRight': assetId,
        },
      ]
    : [];

  await fetch(MANAGEMENT_URL + `/v2/contractdefinitions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      '@context': {
        edc: 'https://w3id.org/edc/v0.0.1/ns/',
      },
      '@id': uuidv4(),
      '@type': 'ContractDefinition',
      'edc:accessPolicyId': policyId,
      'edc:contractPolicyId': policyId,
      'edc:assetsSelector': assetSelector,
    }),
  });
}

export async function deleteContract(contractId: string) {
  await fetch(MANAGEMENT_URL + `/v2/contractdefinitions/${contractId}`, {
    method: 'DELETE',
  });
}
