import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Policy } from '@/store/policies/types';

export type PoliciesState = {
  policyCollection: Policy[];
};

const initialState: PoliciesState = {
  policyCollection: [],
};
export const policiesSlice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    setPolicies: (state, action: PayloadAction<Policy[]>) => {
      state.policyCollection = action.payload;
    },
  },
});

export default policiesSlice.reducer;
export const { setPolicies } = policiesSlice.actions;
