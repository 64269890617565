import React, { useEffect } from 'react';

import { ActionButton, CardView, Title } from '@/components';
import { COUNTER_PARTY_ADDRESSES } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { fetchCatalog, selectCatalogState } from '@/store/catalog';

export function CatalogPage() {
  const dispatch = useAppDispatch();
  const catalog = useAppSelector(selectCatalogState);

  // for visualization purposes, we adapt the catalog collection
  const adaptedCatalogCollection = catalog.catalogCollection.map((catalog) => {
    return {
      ...catalog,
      'edc:participantId': catalog['edc:participantId'].split(':').pop(),
      'edc:name':
        catalog['dcat:dataset'] &&
        (Array.isArray(catalog['dcat:dataset'])
          ? catalog['dcat:dataset']
              .map((dataset) => dataset['edc:name'])
              .join(', ')
          : catalog['dcat:dataset']['edc:name']),
    };
  });

  useEffect(() => {
    dispatch(fetchCatalog(COUNTER_PARTY_ADDRESSES));
  }, []);

  return (
    <div>
      <Title className={'text-center'}>Catalog</Title>
      <CardView
        dataCollection={adaptedCatalogCollection}
        properties={{
          'Participant ID': ['edc:participantId'],
          Dataset: ['edc:name'],
        }}
      />
      <ActionButton
        onclick={async () => {
          // todo: initiate negotiation
        }}
        text={'Negotiate'}
      />
      {catalog.errors.length > 0 && (
        <div className={'text-red-500'}>
          <span className={'font-bold'}>Errors: </span>
          {catalog.errors.map((error, index) => (
            <div key={index}>{JSON.parse(error.message)['dspace:reason']}</div>
          ))}
        </div>
      )}
    </div>
  );
}
