import React, { useEffect } from 'react';

import { CardView } from '@/components/cardview';
import { Title } from '@/components/title';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { fetchCredentials, selectCredentialsState } from '@/store/credentials';

export function CredentialsPage() {
  const dispatch = useAppDispatch();
  const credentials = useAppSelector(selectCredentialsState);

  useEffect(() => {
    dispatch(fetchCredentials());
  }, []);

  return (
    <div>
      <Title className={'text-center'}>Credentials</Title>
      <CardView
        dataCollection={credentials.credentialCollection}
        properties={{
          Issuer: ['iss'],
          Subject: ['sub'],
          'Issued At': ['iat'],
          'Verifiable Credential': ['vc'],
        }}
      />
    </div>
  );
}
