import React, { useEffect, useState } from 'react';

import { ActionButton } from '@/components/button';
import { CardView } from '@/components/cardview';
import { Title } from '@/components/title';
import { PARTICIPANT_NAME } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  addAsset,
  deleteAsset,
  fetchAssets,
  selectAssetsState,
} from '@/store/assets';

export function AssetsPage() {
  const [refresh, setRefresh] = useState(false);
  const dispatch = useAppDispatch();
  const assets = useAppSelector(selectAssetsState);

  useEffect(() => {
    dispatch(fetchAssets());
  }, [refresh]);

  return (
    <div>
      <Title className={'text-center'}>Assets</Title>

      <CardView
        dataCollection={assets.assetCollection}
        onAction={{
          text: 'Delete',
          fn: async (id) => {
            id && (await deleteAsset(id));
            setRefresh(!refresh);
          },
        }}
        properties={{
          Id: ['@id'],
          Name: ['edc:properties', 'edc:name'],
          'Base URL': ['edc:dataAddress', 'edc:ngsild.baseUrl'],
          Tenant: ['edc:dataAddress', 'edc:ngsild.tenant'],
          Type: ['edc:dataAddress', 'edc:ngsild.entityType'],
        }}
      />
      {PARTICIPANT_NAME === 'imec' && (
        <div className={'flex justify-center'}>
          <ActionButton
            onclick={async () => {
              await addAsset('arcelor_mittal');
              setRefresh(!refresh);
            }}
            text={'Add ArcelorMittal Asset'}
          />
          <ActionButton
            onclick={async () => {
              await addAsset('preymesser');
              setRefresh(!refresh);
            }}
            text={'Add Preymesser Asset'}
          />
          <ActionButton
            onclick={async () => {
              await addAsset('vlaamse_waterweg');
              setRefresh(!refresh);
            }}
            text={'Add Vlaamse Waterweg Asset'}
          />
        </div>
      )}
      {PARTICIPANT_NAME === 'eta-schip' && (
        <div className={'flex justify-center'}>
          <ActionButton
            onclick={async () => {
              await addAsset('arcelor_mittal_eta');
              setRefresh(!refresh);
            }}
            text={'Add ArcelorMittal ETA Asset'}
          />
        </div>
      )}
    </div>
  );
}
