import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Asset } from '@/store/assets/types';

export type AssetsState = {
  assetCollection: Asset[];
};

const initialState: AssetsState = {
  assetCollection: [],
};
export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setAssets: (state, action: PayloadAction<Asset[]>) => {
      state.assetCollection = action.payload;
    },
  },
});

export default assetsSlice.reducer;
export const { setAssets } = assetsSlice.actions;
