import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { NavBar } from '@/components/navigation';
import {
  AssetsPage,
  CatalogPage,
  ContractsPage,
  CredentialsPage,
  PoliciesPage,
} from '@/pages';

function App() {
  return (
    <div>
      <Routes>
        <Route element={<NavBar />} path="/">
          <Route element={<CatalogPage />} index path="/" />
          <Route element={<AssetsPage />} path="assets" />
          <Route element={<PoliciesPage />} path="policies" />
          <Route element={<ContractsPage />} path="contracts" />
          <Route element={<CredentialsPage />} path="credentials" />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
