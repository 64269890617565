import { MANAGEMENT_URL } from '@/constants';
import { AppDispatch } from '@/store';
import { Catalog, CatalogError } from '@/store/catalog/index';

import { setCatalogs, setErrors } from './slice';

// fetch from catalog
export function fetchCatalog(counterPartyAddresses: string[]) {
  return async (dispatch: AppDispatch) => {
    const catalogs: Catalog[] = [];
    const errors: CatalogError[] = [];
    for (const counterPartyAddress of counterPartyAddresses) {
      const response = await fetch(MANAGEMENT_URL + `/v2/catalog/request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          '@context': {
            edc: 'https://w3id.org/edc/v0.0.1/ns/',
          },
          '@type': 'CatalogRequest',
          protocol: 'dataspace-protocol-http',
          counterPartyAddress,
        }),
      });

      const payload = await response.json();
      if (response.ok) {
        catalogs.push(payload);
      } else {
        errors.push(...payload);
      }
    }
    dispatch(setCatalogs(catalogs));
    dispatch(setErrors(errors));
  };
}
