import * as jose from 'jose';
import { v4 as uuidv4 } from 'uuid';

import {
  IDENTITY_HUB_URL,
  ISSUER_DID,
  ISSUER_PK,
  PARTICIPANT_DID,
} from '@/constants';
import { AppDispatch } from '@/store';
import {
  Claim,
  VerifiableCredential,
  VerifiableCredentialsResponse,
} from '@/store/credentials/types';
import { getCredentials } from '@/utils/waltid';

import { setCredentials } from './slice';

// fetch credentials from identity hub
export function fetchCredentials() {
  return async (dispatch: AppDispatch) => {
    const response = await fetch(IDENTITY_HUB_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: [
          {
            descriptor: {
              method: 'CollectionsQuery',
            },
          },
        ],
      }),
    });
    const result = (await response.json()) as VerifiableCredentialsResponse;

    if (result.status.code !== 200) {
      console.error(
        'Error fetching credentials from IdentityHub',
        result.status.detail,
      );
    }

    const credentials = result.replies[0].entries.map((entry) => {
      const decode = Buffer.from(entry.data, 'base64').toString('utf-8');

      return jose.decodeJwt(decode) as VerifiableCredential;
    });

    dispatch(setCredentials(credentials));
  };
}

// add credentials to identity hub
async function addCredentials(claims: Claim[]) {
  async function signedJWT(claim: Claim) {
    const alg = 'RS256';
    const privateKey = await jose.importPKCS8(ISSUER_PK, alg);

    const jwt = await new jose.SignJWT({ vc: claim })
      .setIssuer(ISSUER_DID)
      .setSubject(PARTICIPANT_DID)
      .setProtectedHeader({ alg })
      .setIssuedAt()
      .sign(privateKey);

    return Buffer.from(jwt).toString('base64');
  }

  const encodedMessage = async (claim: Claim) => {
    const jwt = await signedJWT(claim);

    return {
      descriptor: {
        method: 'CollectionsWrite',
        dateCreated: Math.floor(Date.now() / 1000),
        recordId: claim['id'] || uuidv4(),
        dataFormat: 'application/vc+jwt',
      },
      data: jwt,
    };
  };

  const response = await fetch(IDENTITY_HUB_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      messages: await Promise.all(claims.map(encodedMessage)),
    }),
  });

  const result = (await response.json()) as VerifiableCredentialsResponse;

  if (result.replies.some((value) => value.status.code !== 200)) {
    console.error(
      'Error adding credentials to IdentityHub',
      result.replies.map((value) => value.status.detail),
    );
  }
}

// Sync credentials from walt.id to identity hub
// This is a one-time operation, there is no way to delete credentials from identity hub
export async function syncCredentials() {
  // fetch credentials from walt.id
  const credentials = await getCredentials();

  // add credentials to identity hub
  await addCredentials(credentials.list);
}
