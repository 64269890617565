import React from 'react';

interface Props {
  dataCollection: any[];
  properties: { [key: string]: string[] };
  onAction?: {
    text: string;
    fn: (id?: string) => void;
  };
}

export const CardView = ({ dataCollection, properties, onAction }: Props) => {
  const keys = Object.keys(properties);
  const values = Object.values(properties);

  return (
    <div className={'flex flex-wrap justify-center max-w-7xl m-auto'}>
      {dataCollection.length === 0 && (
        <div
          className={
            'bg-gray-100 rounded overflow-x-auto shadow-lg m-2 border border-gray-800'
          }
        >
          <div className={'px-6 py-4'}>
            <div className={'text-xl mb-2'}>
              <span className={'font-bold'}>No data found</span>
            </div>
          </div>
        </div>
      )}

      {dataCollection.map((data) => (
        <div
          className={
            'flex-0 bg-gray-100 rounded overflow-x-auto shadow-lg m-2 border border-gray-800 max-w-xl overflow-y-auto'
          }
          // generate unique key from first value
          key={data[values[0][0]]}
        >
          <div className={'px-6 py-4'}>
            {values.map((val, index) => (
              <div className={'text-xl mb-2'} key={keys[index]}>
                <span className={'font-bold'}>{keys[index]}: </span>
                <pre className={'text-lg'}>
                  {JSON.stringify(
                    val.reduce((acc, part) => acc[part] || [], data),
                    null,
                    1,
                  )}
                </pre>
              </div>
            ))}
            {onAction && (
              <div className={'text-xl mb-2'}>
                <button
                  className={
                    'bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'
                  }
                  onClick={() => onAction.fn(data['@id'])}
                >
                  {onAction.text}
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
