import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VerifiableCredential } from '@/store/credentials/types';

export type CredentialsState = {
  credentialCollection: VerifiableCredential[];
};

const initialState: CredentialsState = {
  credentialCollection: [],
};
export const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<VerifiableCredential[]>) => {
      state.credentialCollection = action.payload;
    },
  },
});

export default credentialsSlice.reducer;
export const { setCredentials } = credentialsSlice.actions;
